import React, { useState, useEffect, useContext } from 'react'
import KRGlue from '@lyracom/embedded-form-glue'
import { StoreContext } from '../Context/StoreContext'
import { LanguageContext } from '../Context/LanguageContext'
import UIStorefrontCardTotals from '../Components/UIStorefrontCartTotals'
import { GetKeychainAPI } from '../Utils/GetKeychain'
import { GetFormTokenAPI } from '../Utils/GetFormToken'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faSpinner, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { PostVentasAPI } from '../Utils/PostVentas'
import { SessionContext } from '../Context/SessionContext'
import { CurrencyContext } from '../Context/CurrencyContext'
import {
  metodoEntrega,
  metodoEntregaDelivery,
  metodoRecojo,
  regions,
  metodoPago,
  metodoPagoPlataforma,
  metodoPagoWsp,
} from '../Utils/data'
import { verifyStock } from '../Utils/VerifyStock'
import Swal from 'sweetalert2'
import { useHistory, useLocation } from 'react-router-dom'
import Carrito from './Carrito'
import Select from 'react-select'
import axios from 'axios'
import { GetLinkClipApi } from '../Utils/payment/mx/getLinkClip'
import NavbarCheckout from '../Components/Delicious/NavbarCheckout'
import ButtonPayment from '../Components/Delicious/ButtonPayment'
import { useCouponContext } from '../Context/CouponContext'
import { UserContext } from '../Context/UserContext'

export default function CheckoutFunction(props) {
  const {
    state: {
      newTotal,
      isHomeDelivery,
      ispickupstore,
      isOnlyPrices,
      isPricePerZone,
      isactiveWsp,
      isactivePlatform,
    },
  } = useCouponContext()
  const pro = useContext(UserContext)

  const history = useHistory()
  const location = useLocation()
  const coupon = localStorage.getItem('coupon')
  const { store, setStore } = useContext(StoreContext)
  const { session, setSession } = useContext(SessionContext)
  const language = useContext(LanguageContext)
  const textFinishBuy = language.text.finishBuy
  const [keychain, setKeychain] = useState(null)
  const [formToken, setFormToken] = useState(null)
  const [stepper, setStepper] = useState(0)
  const [saleID, setSaleID] = useState('')
  const [priceDelivery, setPriceDelivery] = useState(0)
  const [deliveryCondition, setDeliveryCondition] = useState('')
  const [priceDiscountDelivery, setPriceDiscountDelivery] = useState(0)
  const [isDiscountDelivery, setIsDiscountDelivery] = useState()
  const [listRegions, setListRegions] = useState(regions)
  const [isActiveButton, setIsActiveButton] = useState(false)
  const [activeFormButton, setactiveFormButton] = useState(false)
  const [loading, setLoading] = useState(false)

  const [metodoEntrega1, setMetodoEntrega1] = useState(
    isHomeDelivery && ispickupstore
      ? metodoEntrega[0]
      : isHomeDelivery
      ? metodoEntregaDelivery[0]
      : metodoRecojo[0]
  )

  const shipping = localStorage.getItem('freeshipping')
  const [currentDomain, setcurrentDomain] = useState()
  useEffect(() => {
    setcurrentDomain(window.location.origin)
  }, [])

  const [_metodoPago, set_MetodoPago] = useState(
    isactivePlatform && isactiveWsp
      ? metodoPago[0]
      : isactivePlatform
      ? metodoPagoPlataforma[0]
      : metodoPagoWsp[0]
  )
  const [thirdDivisionUnique, setThirdDivisionUnique] = useState('')
  const [infoMessage, setInfoMessage] = useState()

  const [hasDelivery, setHasDelivery] = useState('')
  const [infodata, setInfodata] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
  })
  const [paisData, setPaisData] = useState({})
  const consultarPais = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/countries/${userPais}`

    const { data } = await axios.get(url)
    setPaisData(data)
  }

  useEffect(() => {
    consultarPais()
    consultarOnlyPrices()
    consultarData()
  }, [])

  const validateSelect = () => {
    if (isHomeDelivery && ispickupstore) {
      return metodoEntrega
    } else if (isHomeDelivery) {
      return metodoEntregaDelivery
    } else {
      // setMetodoEntrega1(metodoRecojo[0])

      return metodoRecojo
    }
  }
  const validateSelectPago = () => {
    if (isactivePlatform && isactiveWsp) {
      return metodoPago
    } else if (isactivePlatform) {
      return metodoPagoPlataforma
    } else {
      // setMetodoEntrega1(metodoRecojo[0])

      return metodoPagoWsp
    }
  }

  const [selectedItem, setSelectedItem] = useState({
    name: '',
    direccion: '',
    lugar: '',
  })
  const handleCheckboxChange = (item) => {
    setSelectedItem({
      name: item.name,
      direccion: item.adress,
      place: item.place,
    })
  }

  let clientID = JSON.parse(localStorage.getItem('user')).id
  let userPais = JSON.parse(localStorage.getItem('user')).paises
  let phoneWsp = JSON.parse(localStorage.getItem('phone'))
  const [tiendaRecojo, setTiendaRecojo] = useState([])

  //* PICKPOINTS
  const consultarData = async () => {
    const url = `https://ventafazil-strapi-production.up.railway.app/Pickpoints/?cliente=${clientID}&country=${userPais}&isactive=true`
    try {
      const response = await axios.get(url)
      setTiendaRecojo(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const [onlyPrices, setOnlyPrices] = useState([])

  const [thirdDivi, setThirdDivi] = useState([])
  const [onlyPricesDel, setOnlyPricesDel] = useState([])
  const consultarOnlyPrices = async () => {
    let url

    try {
      if (isOnlyPrices) {
        url = `https://ventafazil-strapi-production.up.railway.app/Onlyprices?cliente=${clientID}&country=${userPais}`
        const response = await axios.get(url)

        let idThreeDivision = []
        setOnlyPricesDel(response.data)

        const onPrices = response.data[0].delivery[1][0]

        onPrices.forEach((element) => {
          idThreeDivision.push(element.split('_ID').pop())
        })

        // CODIGOS SEPARADOS ENARREGLO

        consultarDivsionUlt(idThreeDivision)
      } else {
        url = `https://ventafazil-strapi-production.up.railway.app/Priceperzones?cliente=${clientID}&country=${userPais}`

        const response = await axios.get(url)

        const pricePerzones = response.data.map((data) => {
          let idCiudades = []

          data.deliverylist[0].forEach((element) => {
            idCiudades.push(element.split('_ID').pop())
          })

          return {
            discountprice: data.discountprice,
            price: data.price,
            isdiscount: data.isdiscount,
            deliverycondition: data.deliverycondition,
            deliverylist: idCiudades,
            ratename: data.ratename,
          }
        })

        // console.log('CIUDAD', idCiudades)
        // consultarTwoDivisionMX(pricePerzones)
        consultarDivsionUlt(pricePerzones)
      }
    } catch (error) {
      setLoading(false)
    }
  }
  const arrayOne = []
  const arrayTwo = []
  const arrayOP = []

  const [mexTowOptions, setMexTowOptions] = useState([])
  const [mexThreeOptions, setMexThreeOptions] = useState([])
  const [ThreeOption, setThreeOption] = useState([])
  const [mexOneDivision, setMexOneDivision] = useState([])
  const [oneDivisionUnique, setOneDivisionUnique] = useState('')
  const [mexTwoDivision, setMexTwoDivision] = useState([])
  const [twoDivisionUnique, setTwoDivisionUnique] = useState('')

  let mapOptions

  let divisionB

  //* MEXICO PERU

  const consultarDivsionUlt = (idThreeDivision) => {
    switch (userPais) {
      // PERU
      case '63e6a8f8e36e8e0018d9463c':
        divisionB = 'twodivisions'
        consultarTwoDivision(idThreeDivision)
        break

      // MEXICO
      case '63e6a912e36e8e0018d9463d':
        consultarTwoDivisionMX(idThreeDivision)
        break
      default:
        break
    }
  }

  // const consultarTwoDivision = async (idThreeDivision) => {
  //   const url = `https://ventafazil-strapi-production.up.railway.app/twoDivisions?&_limit=-1`
  //   const response = await axios.get(url)

  //   if (isOnlyPrices) {
  //     try {
  //       const _secDiv = response?.data

  //       const twoOptions = response.data.map((option) => ({
  //         id: option.id,
  //         value: option.name,
  //         label: option.name,
  //       }))

  //       _secDiv.forEach((elemento) => {
  //         const dateD = elemento.threedivisions.filter((ef) => {
  //           if (idThreeDivision.includes(ef._id)) {
  //             arrayOne.push(ef)
  //           }
  //         })
  //         mapOptions = arrayOne.map((option) => ({
  //           id: option.id,
  //           value: option.name,
  //           label: option.name,
  //           twodivision: option.twodivision,
  //         }))
  //         setThreeOption(mapOptions)
  //         setMexThreeOptions(mapOptions)
  //       })

  //       const twoDivision = [
  //         ...new Set(arrayOne.map((item) => item.twodivision)),
  //       ]

  //       const filteredOneDivision = twoOptions.filter((objeto) => {
  //         return twoDivision.includes(objeto.id)
  //       })
  //       setMexTwoDivision(filteredOneDivision)

  //       const url = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais}`

  //       const { data } = await axios.get(url)
  //       const _oneDiv = data

  //       const oneOptions = _oneDiv?.map((option) => ({
  //         id: option.id,
  //         value: option.name,
  //         label: option.name,
  //       }))
  //       _oneDiv.forEach((elemento) => {
  //         const dateD = elemento.twodivisions.filter((ef) => {
  //           if (twoDivision.includes(ef._id)) {
  //             arrayTwo.push(ef)
  //           }
  //         })

  //         mapOptions = arrayTwo.map((option) => ({
  //           id: option.id,
  //           value: option.name,
  //           label: option.name,
  //           oneDivision: option.onedivision,
  //         }))
  //         setMexTowOptions(mapOptions)
  //       })

  //       const oneDivision = [
  //         ...new Set(arrayTwo.map((item) => item.onedivision)),
  //       ]

  //       const oneOptionsPER = _oneDiv?.map((option) => ({
  //         id: option.id,
  //         value: option.name,
  //         label: option.name,
  //       }))

  //       const filteredOneDivisionPer = oneOptionsPER.filter((objeto) => {
  //         return oneDivision.includes(objeto.id)
  //       })
  //       setMexOneDivision(filteredOneDivisionPer)
  //     } catch (error) {}
  //   } else {
  //     const secondDiv = response.data.map((data) => {
  //       return {
  //         id: data.id,
  //         name: data.name,
  //         threedivisions: data.threedivisions,
  //         onedivision: data.onedivision,
  //       }
  //     })
  //     let result = []

  //     for (let i = 0; i < idThreeDivision.length; i++) {
  //       let deliverylist = idThreeDivision[i].deliverylist
  //       let departamento = ''
  //       let provincia = ''
  //       let distrito = ''
  //       for (let j = 0; j < secondDiv.length; j++) {
  //         let thirDivisions = secondDiv[j].threedivisions

  //         for (let k = 0; k < thirDivisions.length; k++) {
  //           if (deliverylist.includes(thirDivisions[k].id)) {
  //             distrito = thirDivisions[k].name
  //             provincia = secondDiv[j].name
  //             result.push({
  //               price: idThreeDivision[i].price,
  //               id: secondDiv[j].id,
  //               estado: provincia,
  //               label: distrito,
  //               value: distrito,
  //               deliverycondition: idThreeDivision[i].deliverycondition,
  //               oneDivision: secondDiv[j].onedivision,
  //             })
  //           }
  //         }
  //       }
  //     }

  //     const estados = [...new Set(result.map((item) => item.estado))]

  //     const estadosTransformados = estados.map((estado) => {
  //       return {
  //         label: estado,
  //         value: estado,
  //       }
  //     })
  //     setMexTowOptions(result)

  //     setMexTwoDivision(estadosTransformados)
  //     setMexThreeOptions(result)
  //     const prov = result.map((item) => item.oneDivision.id)
  //     //***first divv
  //     const url = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais}`

  //     const { data } = await axios.get(url)
  //     const _oneDiv = data

  //     const oneDivisionPER = [...new Set(prov.map((item) => item))]

  //     const filteredOneDivisionPer = _oneDiv.filter((objeto) => {
  //       console.log('obbj', objeto)
  //       return prov.includes(objeto.id)
  //     })
  //     const departamentosTra = filteredOneDivisionPer.map((data) => {
  //       return {
  //         id: data.id,
  //         label: data.name,
  //         value: data.name,
  //       }
  //     })
  //     setMexOneDivision(departamentosTra)
  //   }
  // }
  const consultarTwoDivision = async (idThreeDivision) => {
    const url = `https://ventafazil-strapi-production.up.railway.app/twoDivisions?&_limit=-1`
    const response = await axios.get(url)
    if (isOnlyPrices) {
      try {
        const _secDiv = response?.data

        const twoOptions = _secDiv.map((option) => ({
          id: option.id,
          value: option.name,
          label: option.name,
        }))

        const arrayOne = []
        _secDiv.forEach((elemento) => {
          elemento.threedivisions.forEach((ef) => {
            if (idThreeDivision.includes(ef._id)) {
              arrayOne.push(ef)
            }
          })
        })

        const mapOptions = arrayOne.map((option) => ({
          id: option.id,
          value: option.name,
          label: option.name,
          twodivision: option.twodivision,
        }))

        setThreeOption(mapOptions)
        setMexThreeOptions(mapOptions)

        const twoDivision = [
          ...new Set(arrayOne.map((item) => item.twodivision)),
        ]
        const filteredOneDivision = twoOptions.filter((objeto) => {
          return twoDivision.includes(objeto.id)
        })
        setMexTwoDivision(filteredOneDivision)

        const oneDivisionUrl = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais}`
        const { data: oneDivisionData } = await axios.get(oneDivisionUrl)
        const _oneDiv = oneDivisionData

        const oneOptions = _oneDiv?.map((option) => ({
          id: option.id,
          value: option.name,
          label: option.name,
        }))

        const arrayTwo = []
        _oneDiv.forEach((elemento) => {
          elemento.twodivisions.forEach((ef) => {
            if (twoDivision.includes(ef._id)) {
              arrayTwo.push(ef)
            }
          })
        })

        const mapOptionsTwo = arrayTwo.map((option) => ({
          id: option.id,
          value: option.name,
          label: option.name,
          oneDivision: option.onedivision,
        }))
        setMexTowOptions(mapOptionsTwo)

        const oneDivision = [
          ...new Set(arrayTwo.map((item) => item.onedivision)),
        ]
        const oneOptionsPER = oneOptions

        const filteredOneDivisionPer = oneOptionsPER.filter((objeto) => {
          return oneDivision.includes(objeto.id)
        })
        setMexOneDivision(filteredOneDivisionPer)
      } catch (error) {}
    } else {
      const secondDiv = response.data.map((data) => {
        return {
          id: data.id,
          name: data.name,
          threedivisions: data.threedivisions,
          onedivision: data.onedivision,
        }
      })
      let result = []

      for (let i = 0; i < idThreeDivision.length; i++) {
        let deliverylist = idThreeDivision[i].deliverylist
        let departamento = ''
        let provincia = ''
        let distrito = ''
        for (let j = 0; j < secondDiv.length; j++) {
          let thirDivisions = secondDiv[j].threedivisions

          for (let k = 0; k < thirDivisions.length; k++) {
            if (deliverylist.includes(thirDivisions[k].id)) {
              distrito = thirDivisions[k].name
              provincia = secondDiv[j].name
              result.push({
                price: idThreeDivision[i].price,
                id: secondDiv[j].id,
                estado: provincia,
                label: distrito,
                value: distrito,
                deliverycondition: idThreeDivision[i].deliverycondition,
                oneDivision: secondDiv[j].onedivision,
              })
            }
          }
        }
      }

      const estados = [...new Set(result.map((item) => item.estado))]

      const estadosTransformados = estados.map((estado) => {
        return {
          label: estado,
          value: estado,
        }
      })
      setMexTowOptions(result)

      setMexTwoDivision(estadosTransformados)
      setMexThreeOptions(result)
      const prov = result.map((item) => item.oneDivision.id)
      //***first divv
      const url = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais}`

      const { data } = await axios.get(url)
      const _oneDiv = data

      const oneDivisionPER = [...new Set(prov.map((item) => item))]

      const filteredOneDivisionPer = _oneDiv.filter((objeto) => {
        return prov.includes(objeto.id)
      })
      const departamentosTra = filteredOneDivisionPer.map((data) => {
        return {
          id: data.id,
          label: data.name,
          value: data.name,
        }
      })
      setMexOneDivision(departamentosTra)
    }
  }

  const consultarTwoDivisionMX = async (idThreeDivision) => {
    const url = `https://ventafazil-strapi-production.up.railway.app/oneDivisions?&_limit=-1&country=${userPais}`
    const response = await axios.get(url)
    if (isOnlyPrices) {
      try {
        const _oneDiv = response?.data

        const oneOptions = response.data.map((option) => ({
          id: option.id,
          value: option.name,
          label: option.name,
        }))

        _oneDiv.forEach((elemento) => {
          const dateD = elemento.twodivisions.filter((ef) => {
            if (idThreeDivision.includes(ef._id)) {
              arrayOne.push(ef)
            }
          })
          mapOptions = arrayOne.map((option) => ({
            id: option.id,
            value: option.name,
            label: option.name,
            oneDivision: option.onedivision,
          }))
          setMexTowOptions(mapOptions)
        })
        const oneDivision = [
          ...new Set(arrayOne.map((item) => item.onedivision)),
        ]

        const filteredOneDivision = oneOptions.filter((objeto) => {
          return oneDivision.includes(objeto.id)
        })
        setMexOneDivision(filteredOneDivision)
      } catch (error) {}
    } else {
      const oneDiviv = response.data.map((data) => {
        return {
          id: data.id,
          name: data.name,
          twodivisions: data.twodivisions,
        }
      })

      let result = []

      for (let i = 0; i < idThreeDivision.length; i++) {
        let deliverylist = idThreeDivision[i].deliverylist
        let estado = ''
        let municipio = ''
        for (let j = 0; j < oneDiviv.length; j++) {
          let twodivisions = oneDiviv[j].twodivisions

          for (let k = 0; k < twodivisions.length; k++) {
            if (deliverylist.includes(twodivisions[k].id)) {
              municipio = twodivisions[k].name
              estado = oneDiviv[j].name
              result.push({
                price: idThreeDivision[i].price,
                estado: estado,
                label: municipio,
                value: municipio,
                deliverycondition: idThreeDivision[i].deliverycondition,
              })
            }
          }
        }
      }
      const estados = [...new Set(result.map((item) => item.estado))]
      const estadosTransformados = estados.map((estado) => {
        return {
          label: estado,
          value: estado,
        }
      })
      setMexTowOptions(result)

      setMexOneDivision(estadosTransformados)
    }
  }

  // useEffect(() => {
  //   consultarTwoDivision()
  // }, [])

  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const textCar = language.text.car
  const verify = async () => {
    setLoading(true)
    const data = await verifyStock()

    if (data.length >= 1) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
          por favor elimina esos productos
          
          `,
      })
    } else {
      setLoading(false)
      // history.push('/checkout')
    }
  }

  useEffect(() => {
    // modificarrr
    setStepper(0)

    GetKeychainAPI(store.id).then((response) => {
      setKeychain(response)
    }) //GET KEYCHAIN
  }, [])

  // useEffect(() => {
  //   if (
  //     stepper === 1 &&
  //     infodata.name !== '' &&
  //     infodata.surname !== '' &&
  //     infodata.email !== '' &&
  //     infodata.mobile !== '' &&
  //     !metodoEntrega1.value &&
  //     selectedItem.name
  //   ) {
  //     // if (!metodoEntrega1.value && selectedItem.name) {
  //     //   setactiveFormButton(true)
  //     // }
  //     setactiveFormButton(true)
  //   }
  // }, [infodata, selectedItem])

  useEffect(() => {
    if (metodoEntrega1.value) {
      if (
        stepper === 1 &&
        infodata.name !== '' &&
        infodata.surname !== '' &&
        infodata.email !== '' &&
        infodata.mobile !== '' &&
        metodoEntrega1.value &&
        oneDivisionUnique.value &&
        twoDivisionUnique.value &&
        infodata.addres !== undefined &&
        infodata.addres !== '' &&
        infodata.postalCode !== undefined &&
        infodata.postalCode !== ''
      ) {
        if (paisData.numberofdivision === 3 && thirdDivisionUnique.value) {
          setactiveFormButton(true)
        } else if (paisData.numberofdivision === 2) {
          setactiveFormButton(true)
        }
        // setactiveFormButton(true)
      } else {
        setactiveFormButton(false)
      }
    } else {
      if (
        stepper === 1 &&
        infodata.name !== '' &&
        infodata.surname !== '' &&
        infodata.email !== '' &&
        infodata.mobile !== '' &&
        !metodoEntrega1.value &&
        selectedItem.name
      ) {
        // if (!metodoEntrega1.value && selectedItem.name) {
        //   setactiveFormButton(true)
        // }
        setactiveFormButton(true)
      } else {
        setactiveFormButton(false)
      }
    }
  }, [
    infodata,
    oneDivisionUnique,
    twoDivisionUnique,
    thirdDivisionUnique,
    selectedItem,
  ])

  // SE CREA LA VENTA
  function createSale() {
    let products = session.map((product) => {
      return { _id: product.id }
    })

    const coupon1 = JSON.parse(localStorage.getItem('coupon'))
    let delivery

    if (metodoEntrega1.value) {
      let newValue = oneDivisionUnique.label + ', ' + twoDivisionUnique.label
      delivery = {
        label: newValue,
        value: ` ${oneDivisionUnique.label}, ${twoDivisionUnique.label} `,
        price: priceDelivery,
      }
    }

    let arr = {}
    if (coupon1 && coupon.benefit) {
      arr.benefit = coupon1.benefit
      arr.name = coupon1.name
      arr.value = coupon1.value
    }

    let detailProducts = Object.assign(
      infodata,
      { metodoEntrega: metodoEntrega1.value ? 'Delivery' : 'Punto de recojo' },
      { puntoRecojo: selectedItem },
      { detailProducts: session },
      metodoEntrega1.value && { delivery: delivery },
      { coupon: arr }
    )
    const currentDate = new Date()
    const expirationDate = new Date(currentDate.getTime() + 10 * 60 * 1000)
    const expirationDateUTC = expirationDate.toISOString()
    const pricDel =
      shipping === 'true' ? 0 : Number(priceDelivery ? priceDelivery : 0)
    const tot = Number(newTotal) + pricDel

    //!CORREGIR
    PostVentasAPI(store.id, products, tot, detailProducts) //NOMBRE COMPRADOR DEbe de venir del formulario
      .then((response) => {
        setSaleID(response.id)
        const requestBody = {
          amount: tot,
          currency: 'MXN',
          purchase_description: 'Detalle pedido',
          expires_at: expirationDateUTC,
          redirection_url: {
            success: `${currentDomain}/redirection/success/${response.id}`,
            error: `${currentDomain}/redirection/error`,
            default: `${currentDomain}`,
          },
          metadata: {
            me_reference_id: response.id,
            customer_info: {
              name: infodata.name + ' ' + infodata.surname,
              email: infodata.email,
              phone: infodata.mobile,
            },
          },
          override_settings: {
            payment_method: ['CARD', 'CASH'],
            enable_tip: false,
            currency: { show_currency_code: false },
          },
          webhook_url:
            'https://vf-express-production.up.railway.app/webhookclip',
        }

        plataformPay(requestBody, response.id)
      })
      .catch((err) => console.log('AQUI VA', err))
  }

  const plataformPay = (requestBody, response) => {
    switch (userPais) {
      // PERU
      case '63e6a8f8e36e8e0018d9463c':
        console.log('entreee AQZX')
        gettoken(response, infodata.email)

        break

      // MEXICO
      case '63e6a912e36e8e0018d9463d':
        GetLinkClipApi(requestBody, clientID).then((response) => {
          // console.log('lleddd', response)
          if (response.status === 200) {
            window.location.href = response.data.payment_request_url
            localStorage.setItem('bolcd', response.data.payment_request_id)
          } else {
          }
        })
        setLoading(false)

        break
      default:
        break
    }
  }

  // ESTE NECESITA EL ID DE LA VENTA Y EMAIL YA CARGADO
  function gettoken(saleID, userEmail) {
    const pricDel =
      shipping === 'true' ? 0 : Number(priceDelivery ? priceDelivery : 0)
    const tot = (Number(newTotal) + pricDel).toFixed(2)

    GetFormTokenAPI(
      tot,
      store.currency,
      saleID,
      userEmail,
      window.btoa(
        `${keychain.userapirest}:${keychain.passwordproductionapirest}`
      )
    ) //PRODUCCION
      // GetFormTokenAPI(totalPrice(), store.currency, saleID, userEmail, window.btoa(`${keychain.userapirest}:${keychain.passwordtestapirest}`)) //TEST
      .then(async (response) => {
        setFormToken(response.formToken)

        LoadPaymentForm(response.formToken)
        setLoading(false)
      }) //GEt FormToken console.log("izi-formtoken-READY", response.formToken);
  }
  // PIDE EL FORM TOKEN

  function LoadPaymentForm(token) {
    let pasa = false
    setStepper(2)
    KRGlue.loadLibrary(
      keychain.endpoint,
      keychain.publickeyproductionjavascript,
      formToken
    ) //PRODUCCION
      // KRGlue.loadLibrary(keychain.endpoint, keychain.publickeytestjavascript, formToken)//TEST

      .then(({ KR }) =>
        KR.setFormConfig({
          // 'kr-get-url-success': '/thankyou/ ',
          'kr-get-url-refused': '/refused/ ',
          formToken: token,
          'kr-language': 'es-ES',
        })
      )
      .then(({ KR }) => KR.addForm('#myPaymentForm'))
      .then(({ KR, result }) => KR.showForm(result.formId))
      .then(({ KR }) => {
        setStepper(2)

        KR.onSubmit(async (resp) => {
          const data = await verifyStock()
          if (data.length >= 1) {
            Swal.fire({
              icon: 'error',
              title: 'Hubo un problema...',
              text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
                ', '
              )}
        por favor elimina esos productos
        
        `,
            })
            Promise.reject()
            throw new Error('Producto sin stock')
          } else {
            // return false
            history.push(
              '/thankyou/?kr-answer=' + encodeURIComponent(resp.rawClientAnswer)
            )
          }
          return false
        })

        // KR.addForm('#myPaymentForm')
      })

      .catch((error) => {
        console.log(error)
        // debugger
      })
  }

  function onChangeForm(event) {
    let newdatainfo = Object.assign({}, infodata)
    newdatainfo[event.target.name] = event.target.value
    if (
      newdatainfo[event.target.name] === '' ||
      newdatainfo[event.target.name] === 'null'
    ) {
      setIsActiveButton(false)
    }
    setInfodata(newdatainfo)
  }
  const onChangeDeliveryForm = () => {}

  const envioStep1 = async () => {
    setLoading(true)
    const data = await verifyStock()
    if (data.length >= 1) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
          por favor elimina esos productos
          
          `,
      })
    } else {
      setLoading(false)
      setStepper(1)
    }
  }

  // const envioStep2 = async () => {
  //   // console.log('PPPPPffs')
  //   setLoading(true)
  //   const data = await verifyStock()
  //   if (data.length >= 1) {
  //     setLoading(false)
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Hubo un problema...',
  //       text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
  //         ', '
  //       )}
  //         por favor elimina esos productos

  //       `,
  //     })
  //   } else {
  //     createSale()
  //   }
  // }

  const envioStep2 = async () => {
    // console.log('PPPPPffs')
    setLoading(true)
    const data = await verifyStock()
    if (data.length >= 1) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
          por favor elimina esos productos

        `,
      })
    } else {
      createSale()
    }
  }

  const handleSelectMetodo = (selectedOption) => {
    if (selectedOption === metodoEntrega1) {
      return // no hacer nada si el elemento seleccionado es el mismo que el anterior
    }
    setactiveFormButton(false)

    setSelectedItem({})
    const newObject = { ...infodata }
    delete newObject.addres
    delete newObject.postalCode
    setInfodata(newObject)
    setMetodoEntrega1(selectedOption)
    setPriceDelivery(0)
    if (!selectedOption.value) {
      setOneDivisionUnique('')

      setTwoDivisionUnique('')
      setThirdDivisionUnique('')
    }
  }

  const handleSelectMetodoPago = (selectedOption) => {
    set_MetodoPago(selectedOption)
  }

  const handleSelectChange = (e) => {
    setOneDivisionUnique(e)
    setTwoDivisionUnique('')
    setDeliveryCondition('')
    setThirdDivisionUnique('')
    if (isOnlyPrices) {
      setPriceDelivery(0)
      setPriceDiscountDelivery('')
      setIsDiscountDelivery('')
    }
    if (isPricePerZone) {
      setPriceDelivery(0)
    }
  }

  const handleSelectChange1 = (e) => {
    setTwoDivisionUnique(e)
    setThirdDivisionUnique('')

    if (isOnlyPrices) {
      if (paisData.numberofdivision === 3) {
        setPriceDelivery(0)
        setPriceDiscountDelivery('')
        setIsDiscountDelivery('')
      } else {
        setPriceDelivery(onlyPricesDel[0].price)
        setPriceDiscountDelivery(onlyPricesDel[0].discountprice)
        setIsDiscountDelivery(onlyPricesDel[0].isdiscount)
        setDeliveryCondition(onlyPricesDel[0].deliverycondition)
      }
    }
    if (isPricePerZone) {
      setPriceDelivery(e.price)

      setDeliveryCondition(e.deliverycondition)
    }

    // setSelectEstadoVenta(e.value)
  }

  const handleSelectChange2 = (e) => {
    setThirdDivisionUnique(e)
    if (isOnlyPrices) {
      setPriceDelivery(onlyPricesDel[0].price)
      setPriceDiscountDelivery(onlyPricesDel[0].discountprice)
      setIsDiscountDelivery(onlyPricesDel[0].isdiscount)
      setDeliveryCondition(onlyPricesDel[0].deliverycondition)
    }
    if (isPricePerZone) {
      setPriceDelivery(e.price)

      setDeliveryCondition(e.deliverycondition)
    }
  }

  useEffect(() => {
    if (oneDivisionUnique) {
      if (isOnlyPrices) {
        const _twoDv = mexTowOptions.filter((label) => {
          return label.oneDivision == oneDivisionUnique.id
        })
        setMexTwoDivision(_twoDv)
        if (paisData.numberofdivision === 3) {
          const _threeDv = ThreeOption.filter((label) => {
            return label.twodivision == twoDivisionUnique.id
          })

          setMexThreeOptions(_threeDv)
        }
      } else {
        if (paisData.numberofdivision === 3) {
          const _twoDv = mexTowOptions.filter((label) => {
            return label.oneDivision.id == oneDivisionUnique.id
          })

          const filterTH = [
            ...new Set(_twoDv.map((label) => label.estado)),
          ].map((estado) => {
            return {
              label: estado,
              value: estado,
            }
          })
          // const _threeDv = ThreeOption.filter((label) => {
          //   return label.twodivision == twoDivisionUnique.id
          // })
          // console.log('biendo', _twoDv)
          // console.log('biendo2', twoDivisionUnique)

          setMexTwoDivision(filterTH)

          if (twoDivisionUnique) {
            const _threeDv = _twoDv.filter((label) => {
              return label.estado == twoDivisionUnique.label
            })
            setMexThreeOptions(_threeDv)
          }
        } else {
          const _twoDv = mexTowOptions.filter((label) => {
            return label.estado == oneDivisionUnique.label
          })

          setMexTwoDivision(_twoDv)
        }

        // console.log('llegamos 111', _twoDv)
      }
    }
  }, [oneDivisionUnique, twoDivisionUnique])

  return (
    <div className='pt-16 pb-12 bg-gray-100 md:pt-12'>
      {' '}
      <div className='container flex flex-col items-start gap-6 md:flex-row'>
        <div className='flex py-4 flex-col w-full lg:mb-24 grow'>
          <h2 className='max-w-lg px-2 pb-6 text-xl font-bold tracking-tighter text-center md:text-left md:text-3xl'>
            {' '}
            {textFinishBuy.tFinishBuy}
          </h2>

          <NavbarCheckout
            stepper={stepper}
            textFinishBuy={textFinishBuy}
            userPais={userPais}
          />

          <form className='flex flex-col gap-2 text-xs'>
            {stepper === 0 && <Carrito />}
            {stepper === 1 && (
              <div className='flex gap-10'>
                <div className='flex flex-col gap-2 p-4 bg-white border-lg rounded-xl  w-1/2'>
                  TUS DATOS
                  <input
                    type='text'
                    name='name'
                    placeholder={textFinishBuy.billingShipping.name}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.name}
                    onChange={onChangeForm}
                  ></input>
                  <input
                    type='text'
                    name='surname'
                    placeholder={textFinishBuy.billingShipping.lastName}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.surname}
                    onChange={onChangeForm}
                  ></input>
                  <input
                    type='text'
                    name='email'
                    placeholder={textFinishBuy.billingShipping.email}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.email}
                    onChange={onChangeForm}
                  ></input>
                  <input
                    type='text'
                    name='mobile'
                    placeholder={textFinishBuy.billingShipping.phone}
                    className='w-full p-2 border border-gray-400 rounded-sm'
                    value={infodata.mobile}
                    onChange={onChangeForm}
                  ></input>
                  <Select
                    className='w-full py-2 focus:border-yellow-500 '
                    closeMenuOnSelect={true}
                    value={metodoEntrega1}
                    // options={metodoEntrega}
                    options={validateSelect()}
                    onChange={handleSelectMetodo}
                  />
                </div>
                <div className='flex flex-col gap-2 p-4 bg-white border-lg rounded-xl w-1/2'>
                  MÉTODO DE ENTREGA
                  {metodoEntrega1?.value ? (
                    <div>
                      <p>Delivery</p>
                      <input
                        type='text'
                        name='addres'
                        placeholder={textFinishBuy.shippingInformation.address}
                        className='w-full p-2 border border-gray-400 rounded-sm'
                        value={infodata.addres}
                        onChange={onChangeForm}
                      ></input>
                      <Select
                        className='w-full py-2 focus:border-yellow-500 '
                        value={oneDivisionUnique}
                        options={mexOneDivision}
                        onChange={handleSelectChange}
                      />
                      <Select
                        className='w-full py-2 focus:border-yellow-500 '
                        closeMenuOnSelect={true}
                        options={oneDivisionUnique && mexTwoDivision}
                        value={twoDivisionUnique}
                        onChange={handleSelectChange1}
                      />
                      {paisData && paisData.numberofdivision === 3 && (
                        <Select
                          className='w-full py-2 focus:border-yellow-500 '
                          closeMenuOnSelect={true}
                          value={thirdDivisionUnique}
                          options={twoDivisionUnique && mexThreeOptions}
                          onChange={handleSelectChange2}
                        />
                      )}
                      {/* */}
                      <input
                        type='text'
                        name='postalCode'
                        placeholder={
                          textFinishBuy.shippingInformation.postalCode
                        }
                        className='w-full p-2 border border-gray-400 rounded-sm'
                        value={infodata.postalCode}
                        onChange={onChangeForm}
                      ></input>
                      <p>
                        (*)En caso no aparezca tu zona de reparto aún no esta
                        configurado dicha zona de envío
                      </p>
                      {deliveryCondition &&
                        `   CONDICIONES DE ENVIO : ${deliveryCondition}`}
                    </div>
                  ) : (
                    <div>
                      <p>Recojo en tienda</p>
                      <div>
                        {tiendaRecojo?.map((item) => {
                          return (
                            <div
                              key={item.id}
                              className='flex items-center gap-3 mt-2'
                            >
                              <input
                                type='radio'
                                id={item.name}
                                name='item'
                                value={item.name}
                                checked={selectedItem.name === item.name}
                                onChange={() => handleCheckboxChange(item)}
                                className='w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                              />
                              {/* 
                            <label
                              htmlFor={item.name}
                              className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                            >
                              {item.name}
                              {item.adress}
                              {item.place}
                              {item.conditions}
                            </label> */}
                              <div className='text-xs'>
                                <div> {item.name}</div>
                                <div> {item.adress}</div>
                                <div> {item.place}</div>
                                <div className=' text-center whitespace-pre-line md:max-w-md lg:pr-28 md:text-left'>
                                  {item.conditions}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                  <Select
                    className='w-full py-2 focus:border-yellow-500 '
                    closeMenuOnSelect={true}
                    value={_metodoPago}
                    // options={metodoEntrega}
                    options={validateSelectPago()}
                    onChange={handleSelectMetodoPago}
                  />
                </div>
              </div>
            )}
            {stepper === 2 && (
              <div className='flex flex-col gap-2 p-4 bg-white border-lg rounded-xl '>
                <div className='py-2 text-sm font-semibold text-gray-500 uppercase'>
                  {' '}
                  listo para completar tu pedido
                </div>
              </div>
            )}
          </form>
        </div>

        <div className='flex flex-col items-center justify-center w-full max-w-md bg-gray-100 shrink-0 md:max-w-sm form rounded-2xl relative'>
          <UIStorefrontCardTotals
            pricedelivery={priceDelivery}
            shipping={shipping}
          />
          {loading && (stepper == 0 || stepper == 1) && (
            <div className='flex justify-center items-center w-full h-full absolute'>
              <span>
                <FontAwesomeIcon
                  icon={faSpinner}
                  className='spinner-border animate-spin rounded-full font-bold text-6xl text-blue-500'
                />
              </span>
            </div>
          )}
          <div className='flex flex-row justify-center w-full p-2 bg-white rounded'>
            {/* {store.activeizi ? ( */}
            <ButtonPayment
              stepper={stepper}
              textFinishBuy={textFinishBuy}
              userPais={userPais}
              loading={loading}
              envioStep1={envioStep1}
              envioStep2={envioStep2}
              textCar={textCar}
              activeFormButton={activeFormButton}
              _metodoPago={_metodoPago}
              phoneWsp={phoneWsp}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
